
import { defineComponent, ref, onBeforeMount } from "vue";
import { hideModal } from "@/core/helpers/dom";
import { ApiSalesInteractions, ApiBase } from "@/core/api";
import mixin from "@/mixins";
import _ from "lodash";
import { useI18n } from "vue-i18n";
import { commonChangeDefaultDate } from "@/core/directive/function/common";

export default defineComponent({
  name: "add-interactive-activities-modal",
  props: {
    accountId: { type: String, required: false },
    targetType: { type: String, required: false },
  },
  components: {},
  emits: ["update-list"],
  setup(props, { emit }) {
    const loading = ref<boolean>(false);
    const formRef = ref<null | HTMLFormElement>(null);
    const addInteractiveActivitiesModalRef = ref<null | HTMLElement>(null);
    const submitButton = ref<HTMLElement | null>(null);
    const { t } = useI18n();
    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const formData = ref({
      target_name: "",
      target_owner: "",
      target_type: props.targetType,
      account_id: props.accountId,
      account_groups_id: "",
      mb_segmentation: [],
      start: "",
      close_date: "",
      stage: "",
      probability: 0,
      target_amount: 0,
      completion: "",
      requried_resources: [],
    });

    const rules = ref({
      target_name: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      target_owner: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      account_id: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      target_type: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      close_date: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      start: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
    });

    const ownerOptions = ref([]);
    const ownerLoading = ref<boolean>(false);
    const typeOptions = ref([]);
    const stageOptions = ref([]);
    const influencerOptions = ref([]);
    const influencerLoading = ref<boolean>(false);
    const influencerGroupOptions = ref([]);
    const influencerGroupLoading = ref<boolean>(false);
    const requriedResourcesOptions = ref([]);

    const getDropdownOptions = async () => {
      const { data } = await ApiBase.getTaggingData({
        short_key: [
          "sales_interactions_target_type",
          "sales_interactions_requried_resources",
          "interactive_activity_stage",
        ],
      });
      if (data.code == 0) {
        typeOptions.value = data.data.sales_interactions_target_type.items;
        requriedResourcesOptions.value =
          data.data.sales_interactions_requried_resources.items;
        stageOptions.value = data.data.interactive_activity_stage.items;
      }
    };

    const switchUserType = (type: number, status: boolean) => {
      switch (type) {
        case 0:
          status ? (ownerLoading.value = true) : (ownerLoading.value = false);
          break;
        default:
          break;
      }
    };

    const switchUserValue = (type: number, data: any) => {
      switch (type) {
        case 0:
          ownerOptions.value = data;
          break;
        default:
          break;
      }
    };

    const getUserSourceData = async (
      type: number,
      value?: string,
      id?: string
    ) => {
      switchUserType(type, true);
      let params =
        value != ""
          ? {
              search_value: value,
            }
          : {
              id: id,
            };

      const { data } = await ApiBase.getUserSourceData(params);
      switchUserType(type, false);
      if (data.code == 0) {
        switchUserValue(type, data.data);
      }
    };

    const debounceUserSearch = _.debounce(getUserSourceData, 1000);
    const searchOwnerItems = (query) => {
      debounceUserSearch(0, query);
    };

    const getAccountSourceData = async (value?: string, id?: string) => {
      influencerLoading.value = true;
      let params =
        value != ""
          ? {
              search_value: value,
            }
          : {
              id: id,
            };
      const { data } = await ApiBase.getAccountSourceData(params);
      influencerLoading.value = false;
      if (data.code == 0) {
        influencerOptions.value = data.data;
      }
    };
    const debounceAccountSearch = _.debounce(getAccountSourceData, 1000);
    const searchInfluencerItems = (query) => {
      debounceAccountSearch(query);
    };

    const getInfluencerGroupSourceData = async () => {
      influencerGroupLoading.value = true;
      const { data } = await ApiBase.getInfluencerGroupSourceData({
        max_item: "all",
      });
      influencerGroupLoading.value = false;
      if (data.code == 0) {
        influencerGroupOptions.value = data.data;
      }
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate((valid) => {
        if (valid) {
          loading.value = true;
          if (submitButton.value) {
            submitButton.value.setAttribute("data-kt-indicator", "on");
          }
          ApiSalesInteractions.addSalesInteractions(formData.value)
            .then(({ data }) => {
              loading.value = false;
              submitButton.value?.removeAttribute("data-kt-indicator");
              if (data.code == 0) {
                showFormSubmitSuccessMsg(() => {
                  resetForm();
                  hideModal(addInteractiveActivitiesModalRef.value);
                  emit("update-list");
                });
              } else {
                showServerErrorMsg(data);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const handleDiscard = () => {
      hideModal(addInteractiveActivitiesModalRef.value);
      resetForm();
    };

    const resetForm = () => {
      formRef.value?.resetFields();
    };

    onBeforeMount(() => {
      getDropdownOptions();
      getInfluencerGroupSourceData();
    });

    return {
      commonChangeDefaultDate,
      loading,
      props,
      formData,
      rules,
      formRef,
      addInteractiveActivitiesModalRef,
      submitButton,
      ownerOptions,
      typeOptions,
      influencerOptions,
      influencerLoading,
      influencerGroupOptions,
      stageOptions,
      influencerGroupLoading,
      requriedResourcesOptions,
      t,
      searchOwnerItems,
      searchInfluencerItems,
      submit,
      handleDiscard,
      resetForm,
    };
  },
});
