
import { defineComponent, onBeforeMount, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import {
  ApiAccount,
  ApiSalesInteractions,
  ApiTaskManagement,
} from "@/core/api";
import { useI18n } from "vue-i18n";
import { ELDatatable } from "magicbean-saas-common";
import AddInteractiveActivitiesModal from "@/views/interactive-marketing/interactive-activities/AddInteractiveActivitiesModal.vue";
import AddCallOutRecordsModal from "@/views/task-management/call-out-records/AddCallOutRecordsModal.vue";
import AddQuickInquiryModal from "@/views/task-management/quick-inquiry/AddQuickInquiryModal.vue";
import {
  formatDate,
  formatDateTime,
  setModuleBCN,
} from "@/core/directive/function/common";

export default defineComponent({
  name: "account-interactions",
  components: {
    ELDatatable,
    AddInteractiveActivitiesModal,
    AddCallOutRecordsModal,
    AddQuickInquiryModal,
  },
  setup() {
    const loading = ref<boolean>(false);
    const route = useRoute();
    const router = useRouter();
    const { t } = useI18n();

    const accountId = route.params.id;
    const taskSource = ref<string>("");

    const options = ref({
      campaignLoading: true,
      campaignList: [],
      campaignColumns: [
        {
          label: t("marketingCampaigns.campaignId"),
          prop: "id",
        },
        {
          label: t("marketingCampaigns.campaignName"),
          prop: "name",
        },
        {
          label: t("marketingCampaigns.campaignPurpose"),
          prop: "__show.purpose",
        },
        {
          label: t("marketingCampaigns.startDate"),
          prop: "start_date",
          slot: true,
        },
        {
          label: t("marketingCampaigns.endDate"),
          prop: "end_date",
          slot: true,
        },
        {
          label: t("marketingCampaigns.status"),
          prop: "status",
          slot: true,
        },
        {
          label: "",
          prop: "actions",
          width: 100,
          align: "right",
          slot: true,
        },
      ],
      campaignCurrentPage: 1,
      campaignTotal: 0,
      campaignTotalPage: 0,
      campaignPageSize: 10,
      salesInteractionLoading: true,
      salesInteractionList: [],
      salesInteractionColumns: [
        {
          label: t("interactiveActivities.targetName"),
          prop: "target_name",
        },
        {
          label: t("interactiveActivities.influencer"),
          prop: "__show.account",
        },
        {
          label: t("interactiveActivities.targetOwner"),
          prop: "__show.target_owner",
        },
        {
          label: t("interactiveActivities.start"),
          prop: "start",
          slot: true,
        },
        {
          label: t("interactiveActivities.closeDate"),
          prop: "close_date",
          slot: true,
        },
        {
          label: t("interactiveActivities.completion"),
          prop: "completion",
        },
        {
          label: "",
          prop: "actions",
          width: 100,
          align: "right",
          slot: true,
        },
      ],
      salesInteractionCurrentPage: 1,
      salesInteractionTotal: 0,
      salesInteractionTotalPage: 0,
      salesInteractionPageSize: 10,
      callOutLoading: true,
      callOutList: [],
      callOutColumns: [
        {
          label: t("callOutRecords.contact"),
          prop: "__show.concat",
        },
        {
          label: t("callOutRecords.taskOwner"),
          prop: "__show.task_owner",
        },
        {
          label: t("callOutRecords.dueDate"),
          prop: "due_date",
          slot: true,
        },
        {
          label: t("callOutRecords.actionDate"),
          prop: "action_date",
          slot: true,
        },
        {
          label: t("callOutRecords.importance"),
          prop: "importance",
          slot: true,
        },
        {
          label: t("callOutRecords.comment"),
          prop: "comment",
          showOverflowTooltip: true,
        },
        {
          label: "",
          prop: "actions",
          width: 100,
          align: "right",
          slot: true,
        },
      ],
      callOutCurrentPage: 1,
      callOutTotal: 0,
      callOutTotalPage: 0,
      callOutPageSize: 10,
      quickInquiryLoading: true,
      quickInquiryList: [],
      quickInquiryColumns: [
        {
          label: t("quickInquiry.requestDate"),
          prop: "request_date",
          slot: true,
        },
        {
          label: t("quickInquiry.requestedBy"),
          prop: "__show.requested_by_name",
        },
        {
          label: t("quickInquiry.taskOwner"),
          prop: "__show.task_owner",
        },
        {
          label: t("quickInquiry.request"),
          prop: "request",
          showOverflowTooltip: true,
        },
        {
          label: t("quickInquiry.quote"),
          prop: "quote",
          showOverflowTooltip: true,
        },
        {
          label: t("quickInquiry.quoteAmount"),
          prop: "quote_amount",
          slot: true,
          align: "right",
        },
        {
          label: "",
          prop: "actions",
          width: 100,
          align: "right",
          slot: true,
        },
      ],
      quickInquiryCurrentPage: 1,
      quickInquiryTotal: 0,
      quickInquiryTotalPage: 0,
      quickInquiryPageSize: 10,
    });

    const getImportanceLabel = (row, column, cellValue, index) => {
      const status = new Map([
        [1, t("callOutRecords.important")],
        [2, t("callOutRecords.conventional")],
        [3, t("callOutRecords.invalid")],
      ]);
      return status.get(cellValue);
    };

    const getStatusLabel = (status) => {
      let str = "",
        statusClass = "";
      switch (status) {
        case 10:
        case 1:
          str = t("marketingCampaigns.plan");
          statusClass = "badge-light-info";
          break;
        case 20:
        case 3:
          str = t("marketingCampaigns.processing");
          statusClass = "badge-light-warning";
          break;
        case 30:
        case 2:
          str = t("marketingCampaigns.completed");
          statusClass = "badge-light-success";
          break;
        case 40:
          str = t("marketingCampaigns.void");
          statusClass = "badge-light-danger";
          break;
        default:
          break;
      }
      return { str, statusClass };
    };

    const getCampaignList = async () => {
      options.value.campaignLoading = true;
      const { data } = await ApiAccount.getAccountCampaignList({
        account_id: route.params.id,
        page: options.value.campaignCurrentPage,
        page_size: options.value.campaignPageSize,
      });
      options.value.campaignLoading = false;
      if (data.code == 0) {
        options.value.campaignList = data.data.items;
        options.value.campaignTotal = data.data.total;
        options.value.campaignTotalPage = data.data.total_page;
      }
    };

    const getUpdateCampaignList = () => {
      options.value.campaignCurrentPage = 1;
      getCampaignList();
    };

    const setCampaignPerPage = (val) => {
      options.value.campaignPageSize = val;
      getCampaignList();
    };

    const handleCampaignCurrentChange = (val: number) => {
      options.value.campaignCurrentPage = val;
      getCampaignList();
    };

    const getSalesInteractionList = async () => {
      options.value.salesInteractionLoading = true;
      const { data } = await ApiSalesInteractions.getSalesInteractionsList({
        filter_group: [
          {
            field: "account_id",
            value: route.params.id,
            condition: "eq",
          },
        ],
        page: options.value.salesInteractionCurrentPage,
        page_size: options.value.salesInteractionPageSize,
      });
      options.value.salesInteractionLoading = false;
      if (data.code == 0) {
        options.value.salesInteractionList = data.data.items;
        options.value.salesInteractionTotal = data.data.total;
        options.value.salesInteractionTotalPage = data.data.total_page;
      }
    };

    const getUpdateSalesInteractionList = () => {
      options.value.salesInteractionCurrentPage = 1;
      getSalesInteractionList();
    };

    const setSalesInteractionPerPage = (val) => {
      options.value.salesInteractionPageSize = val;
      getSalesInteractionList();
    };

    const handleSalesInteractionCurrentChange = (val: number) => {
      options.value.salesInteractionCurrentPage = val;
      getSalesInteractionList();
    };

    const getCallOutList = async () => {
      options.value.callOutLoading = true;
      const { data } = await ApiTaskManagement.getCallOutRecordsList({
        filter_group: [
          {
            field: "contact",
            value: route.params.id,
            condition: "eq",
          },
        ],
        page: options.value.callOutCurrentPage,
        page_size: options.value.callOutPageSize,
      });
      options.value.callOutLoading = false;
      if (data.code == 0) {
        options.value.callOutList = data.data.items;
        options.value.callOutTotal = data.data.total;
        options.value.callOutTotalPage = data.data.total_page;
      }
    };

    const getUpdateCallOutList = () => {
      options.value.callOutCurrentPage = 1;
      getCallOutList();
    };

    const setCallOutPerPage = (val) => {
      options.value.callOutPageSize = val;
      getCallOutList();
    };

    const handleCallOutCurrentChange = (val: number) => {
      options.value.callOutCurrentPage = val;
      getCallOutList();
    };

    const getQuickInquiryList = async () => {
      options.value.quickInquiryLoading = true;
      const { data } = await ApiTaskManagement.getQuickInquiryList({
        filter_group: [
          {
            field: "requested_by",
            value: route.params.id,
            condition: "eq",
          },
        ],
        page: options.value.quickInquiryCurrentPage,
        page_size: options.value.quickInquiryPageSize,
      });
      options.value.quickInquiryLoading = false;
      if (data.code == 0) {
        options.value.quickInquiryList = data.data.items;
        options.value.quickInquiryTotal = data.data.total;
        options.value.quickInquiryTotalPage = data.data.total_page;
      }
    };

    const getUpdateQuickInquiryList = () => {
      options.value.quickInquiryCurrentPage = 1;
      getQuickInquiryList();
    };

    const setQuickInquiryPerPage = (val) => {
      options.value.quickInquiryPageSize = val;
      getQuickInquiryList();
    };

    const handleQuickInquiryCurrentChange = (val: number) => {
      options.value.quickInquiryCurrentPage = val;
      getQuickInquiryList();
    };

    const backToList = () => {
      if (route.path.indexOf("account-conversions") != -1) {
        router.push("/interactive-marketing/account-conversions");
      } else {
        router.push("/influencer-platform/influencer-accounts");
      }
    };

    const getFormInfo = async () => {
      loading.value = true;
      Promise.all([
        getUpdateSalesInteractionList(),
        getUpdateCallOutList(),
        getUpdateQuickInquiryList(),
        getUpdateCampaignList(),
      ])
        .then(() => {
          loading.value = false;
        })
        .catch((error) => {
          console.log(error);
        });
    };

    onMounted(() => {
      if (route.path.indexOf("account-conversions") != -1) {
        taskSource.value = "2";
      } else {
        taskSource.value = "1";
      }
      setModuleBCN(t, route, router);
    });

    onBeforeMount(() => {
      getFormInfo();
    });

    return {
      t,
      formatDate,
      formatDateTime,
      loading,
      accountId,
      taskSource,
      options,
      getImportanceLabel,
      getStatusLabel,
      setCampaignPerPage,
      handleCampaignCurrentChange,
      getUpdateSalesInteractionList,
      setSalesInteractionPerPage,
      handleSalesInteractionCurrentChange,
      getUpdateCallOutList,
      setCallOutPerPage,
      handleCallOutCurrentChange,
      getUpdateQuickInquiryList,
      setQuickInquiryPerPage,
      handleQuickInquiryCurrentChange,
      backToList,
    };
  },
});
